// Load fontawesome
import '@fortawesome/fontawesome-free/js/all';

// Load Styles
import '../scss/main.scss';

window.lastBuild = new Date().toISOString();
window.buildVersion = '1.0.49';

// import and set local Sorage
import localforage from 'localforage';
window.localforage = localforage;
var siteStorage = localforage.createInstance({
    name: "siteStorage"
});
window.siteStorage = siteStorage;

//import and set Cookies
import Cookies from 'js-cookie'
window.Cookies = Cookies;

// Load jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;

// Load Bootstrap init
import {initBootstrap} from "./bootstrap";
// import * as bootstrap from "bootstrap";
// Loading bootstrap with optional features
initBootstrap({
    tooltip: true,
    popover: true,
    toasts: true,
});

//import Notyf
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
window.notyf = new Notyf();

//import Handlebars from "handlebars/lib/handlebars";
import Handlebars from 'handlebars/dist/cjs/handlebars';

// import Moment
import moment from "moment";
window.moment = moment;
window.moment.locale('ro');

console.log('App: Main script v'+buildVersion+' -> Loaded');

function downloadFile(file) {

    var downloadImgBtnTitle = '<i class="fa-solid fa-download fa-fw"></i> Descarcă';
    var downloadingImgBtnTitle = '<i class="fas fa-spinner fa-spin fa-fw"></i> se descarcă...';
    var succesImgBtnTitle = '<i class="fa-solid fa-check fa-fw"></i> Imagine descărcată';

    jQuery('#imgButtonDownloadWatermarked').removeClass("btn-primary").addClass("btn-secondary").html(downloadingImgBtnTitle);
    jQuery( "#imgButtonDownloadWatermarked" ).prop( "disabled", true );

    fetch(file)
    .then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'unimedia-'+Date.now()+'';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        jQuery('.imgProgressState.three').removeClass("text-muted fst-italic").addClass("text-decoration-line-through");
        jQuery('.imgProgressState.three span').wizardifyListBadge('on');
        jQuery('#imgButtonDownloadWatermarked').removeClass("btn-secondary").addClass("btn-success").html(succesImgBtnTitle);
    })
    .catch((e) => {
        console.log('oh no!')
    });
}

function showLoader(id)
{
    console.log('App: Main script -> showLoader');
    document.getElementById(id).innerHTML = template_Loader({});
}

function goToRoute(route, container, template, data = {}, loader = false)
{
    console.log('App: Main script -> goToRoute');

    const nextURL = 'https://publisher.yoda.md' + route;
    const nextTitle = '';
    const nextState = '';

    if (loader) {
        document.getElementById(container).innerHTML = template_Loader({});
    }

    switch (template) {
        case 'homeshort':
            console.log('App: Main script -> goToRoute -> homeshort');
            document.getElementById(container).innerHTML = template_HomeShort({});
        break;

        case 'photo':
            console.log('App: Main script -> goToRoute -> photo');
            document.getElementById(container).innerHTML = template_Photo({});
        break;

        case 'video':
            console.log('App: Main script -> goToRoute -> video');
            document.getElementById(container).innerHTML = template_Video({});
        break;

        case 'profile':
            console.log('App: Main script -> goToRoute -> profile');
            document.getElementById(container).innerHTML = template_Profile({});
        break;
        default:
            console.log('ERROR: App: Main script -> goToRoute -> template not found');
    }

    window.history.pushState(nextState, nextTitle, nextURL);
}

function bindRouteLinks()
{
    console.log('App: Main script -> bindRouteLinks -> Init');

    jQuery('#uiArea').on('click', '.router-link', function(e){
        e.preventDefault();
        var dataRoute = jQuery(this).attr("data-route-target");
        var dataContainer = jQuery(this).attr("data-container-target");
        var dataTemplate = jQuery(this).attr("data-container-template");
        console.log('App: Main script -> bindRouteLinks -> dataRoute: ' + dataRoute);
        console.log('App: Main script -> bindRouteLinks -> dataContainer: ' + dataContainer);
        console.log('App: Main script -> bindRouteLinks -> dataTemplate: ' + dataTemplate);

        goToRoute(dataRoute, dataContainer, dataTemplate, {}, true);
    });    
}

jQuery.fn.wizardifyListBadge = function(toggle) {
    // bg-secondary bg-success
    const list_OffState = '<i class="fa-solid fa-xmark fa-fw"></i>';
    const list_OnState = '<i class="fa-solid fa-check fa-fw"></i>';
    var listToggleState;
    var removeClasses;
    var addClasses;

    if (toggle == 'on') {
        listToggleState = list_OnState;
        removeClasses = 'bg-secondary';
        addClasses = 'bg-success';
    } else {
        listToggleState = list_OffState;
        removeClasses = 'bg-success';
        addClasses = 'bg-secondary';
    }

    jQuery(this).removeClass(removeClasses).addClass(addClasses).html(listToggleState);
};

var template_Loader = require("../../templates/handlebars/src/loader.handlebars");
var template_Login = require("../../templates/handlebars/src/login.handlebars");
var template_Home = require("../../templates/handlebars/src/home.handlebars");
var template_HomeShort = require("../../templates/handlebars/src/homeshort.handlebars");
var template_Photo = require("../../templates/handlebars/src/foto.handlebars");
var template_Video = require("../../templates/handlebars/src/video.handlebars");
var template_Profile = require("../../templates/handlebars/src/profil.handlebars");
var template_Img_ProgressList = require("../../templates/handlebars/src/foto.imgstateprogress.handlebars");

jQuery(document).on("click","#imgReset", function()
{
    jQuery( "#imgFile" ).prop( "disabled", false );
    jQuery('#imgFile').val(null);

    jQuery('#imgPreviewSrc').attr("src", "");
    jQuery('#imgPreviewSrc').css('display', 'none');   
    jQuery('#imgButtonProcess').css('display', 'none');
    jQuery('#imgButtonDownloadWatermarked').css('display', 'none');

    jQuery('#imgButtonProcess').removeClass("btn-secondary").removeClass("btn-success").addClass("btn-primary").html('<i class="fa-solid fa-cloud-arrow-up fa-fw"></i> Procesează');
    jQuery( "#imgButtonProcess" ).prop( "disabled", false );

    jQuery('#imgButtonDownloadWatermarked').removeClass("btn-secondary").removeClass("btn-success").addClass("btn-primary").html('<i class="fa-solid fa-download fa-fw"></i> Descarcă');
    jQuery( "#imgButtonDownloadWatermarked" ).prop( "disabled", false );

    jQuery('.imgProgressState.one').removeClass("text-decoration-line-through").addClass("text-muted fst-italic");
    jQuery('.imgProgressState.one span').wizardifyListBadge('off');

    jQuery('.imgProgressState.two').removeClass("text-decoration-line-through").addClass("text-muted fst-italic");
    jQuery('.imgProgressState.two span').wizardifyListBadge('off');

    jQuery('.imgProgressState.three').removeClass("text-decoration-line-through").addClass("text-muted fst-italic");
    jQuery('.imgProgressState.three span').wizardifyListBadge('off');
    
    jQuery('#imgReset').css('display', 'none');
});

jQuery(document).on("click","#imgButtonDownloadWatermarked", function()
{
    console.log('App: Main script -> imgButtonDownloadWatermarked -> Init');

    var inputImage = jQuery('#minioCleanFileUrl').val();
    var markRatio = jQuery('#imgReadMarkRatio').val();

    console.log('App: Main script -> imgButtonDownloadWatermarked -> inputImage: ' + inputImage);
    console.log('App: Main script -> imgButtonDownloadWatermarked -> markRatio: ' + markRatio);

    // 1280x720
    var watermarkImg = "https://s3.yoda.md/publisher/w/unimedia.png";
    var watermarkedResultUrl = "https://quickchart.io/watermark?mainImageUrl="+encodeURI(inputImage)+"&markImageUrl="+encodeURI(watermarkImg)+"&markRatio="+markRatio+"&opacity=1&position=center";

    console.log('App: Main script -> imgButtonDownloadWatermarked -> watermarkedResultUrl: ' + watermarkedResultUrl);

    downloadFile(watermarkedResultUrl);
});

jQuery(document).on("click","#imgButtonProcess", function()
{
    jQuery( "#imgButtonProcess" ).prop( "disabled", true );

    var workingImgBtnTitle = '<i class="fas fa-spinner fa-spin fa-fw"></i> se procesează...';
    var defaultImgBtnTitle = '<i class="fa-solid fa-cloud-arrow-up fa-fw"></i> Procesează';
    var succesImgBtnTitle = '<i class="fa-solid fa-check fa-fw"></i> Imagine procesată';

    console.log('App: Main script -> imgButtonProcess -> Init');

    jQuery('#imgButtonProcess').removeClass("btn-primary").addClass("btn-secondary").html(workingImgBtnTitle);

    var fd = new FormData();
    var files = jQuery('#imgFile')[0].files;

    if(files.length > 0 ){
        fd.append('file',files[0]);
        fd.append('imgReadWidth',jQuery('#imgReadWidth').val());
        fd.append('imgReadHeight',jQuery('#imgReadHeight').val());

        jQuery.ajax({
            url: 'https://service.yoda.md/ypt/photo/process',
            type: 'post',
            data: fd,
            contentType: false,
            processData: false,
            success: function(response){
                if(response != 0){
                    jQuery('#minioCleanFileUrl').val(response.payload.file);

                    if (response.payload.width > response.payload.height) {
                        jQuery('#imgReadMarkRatio').val('1.3');
                    }

                    if (response.payload.height > response.payload.width) {
                        jQuery('#imgReadMarkRatio').val('1.7');
                    }

                    jQuery('.imgProgressState.two').removeClass("text-muted fst-italic").addClass("text-decoration-line-through");
                    jQuery('.imgProgressState.two span').wizardifyListBadge('on');

                    jQuery('#imgButtonProcess').removeClass("btn-secondary").addClass("btn-success").html(succesImgBtnTitle);
                    jQuery( "#imgButtonProcess" ).prop( "disabled", true );

                    jQuery('#imgButtonDownloadWatermarked').css('display', 'inline-block');
                }else{
                    jQuery('#imgButtonProcess').removeClass("btn-secondary").addClass("btn-primary").html(defaultImgBtnTitle);
                    console.log('App: Main script -> imgButtonProcess -> response: 0 ERROR');
                }
            },
        });
    }else{
        jQuery('#imgButtonProcess').removeClass("btn-secondary").addClass("btn-primary").html(defaultImgBtnTitle);
        console.log('App: Main script -> imgButtonProcess -> No file selected');
    }
});

jQuery(document).on("change","#imgFile", function()
{
    console.log('App: Main script -> imgFile -> Init');

    var uploadFile = $(this);
    var files = !!this.files ? this.files : [];
    if (!files.length || !window.FileReader) return;

    console.log('App: Main script -> imgFile -> file info:');
    console.log(files[0]);


    if (/^image/.test( files[0].type)){
        var reader = new FileReader();

        reader.readAsDataURL(files[0]);

        reader.onloadend = function(){ 
            var image = new Image();

            image.src = this.result;

            image.onload = function () {
                var height = this.height;
                var width = this.width;

                console.log('App: Main script -> imgLoaded -> image -> height original: ' + height);
                console.log('App: Main script -> imgLoaded -> image -> width original: ' + width);

                jQuery('#imgReadWidth').val(width);
                jQuery('#imgReadHeight').val(height);

                return true;
            };

            jQuery('#imgPreviewSrc').attr("src", this.result);
            jQuery('#imgPreviewSrc').css('display', 'block');
            jQuery('#imgReset').css('display', 'inline-block');
            jQuery('#imgButtonProcess').css('display', 'inline-block');
            // jQuery('#imgButtonDownloadWatermarked').css('display', 'inline-block');
            
            jQuery('.imgProgressState.one').removeClass("text-muted fst-italic").addClass("text-decoration-line-through");
            jQuery('.imgProgressState.one span').wizardifyListBadge('on');

            jQuery('#minioValidImg').val('1');
            jQuery( "#imgFile" ).prop( "disabled", true );
            
        }
    } else {
        return notyf.error('Formularul acceptă doar imagini!');
    }
});

jQuery( document ).ready(function() {
    console.log('App: Document -> Ready -> Init');
    
    const currentPath = window.location.href.split(/[?#]/)[0].replaceAll(window.location.origin, '');

    console.log('App: Document -> Ready -> currentPath: ' + currentPath);

    if (currentPath === '/') {
        /* se încarcă prima pagină */
        // document.getElementById('uiArea').innerHTML = template_Home({});
        jQuery('#uiArea').html(template_Home({}));
        jQuery('#lastBuild_home_video').html(moment(window.lastBuild).format('LLL'));
        jQuery('#lastBuild_home_photo').html(moment(window.lastBuild).format('LLL'));
    } else if (currentPath === '/tools/photo/') {
        /* se încarcă pagina cu fotografii */
        document.getElementById('mainLoggedContent').innerHTML = template_Photo({});
    } else if (currentPath === '/tools/video/') {
        /* se încarcă pagina cu videoclipuri */
        document.getElementById('mainLoggedContent').innerHTML = template_Video({});
    } else if (currentPath === '/tools/profile/') {
        /* se încarcă pagina cu profil */
        document.getElementById('mainLoggedContent').innerHTML = template_Profile({});
    } else {
        /* se încarcă pagina cu login */
        document.getElementById('uiArea').innerHTML = template_Login({});
    }
    
    bindRouteLinks();
});